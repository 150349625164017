import { ITestStep } from "./TestStep";

const DEFAULT_TIMEOUT = 60 * 1000;

const getCoordinates = async (
  recorderExtId: any,
  startTime: number,
): Promise<any> => {
  let response;
  while (Date.now() - startTime < DEFAULT_TIMEOUT) {
    console.time("getCandidates");
    response = await chrome.runtime.sendMessage(recorderExtId, {
      action: "getCandidates",
    });

    console.log("candidates: ", response);
    console.timeEnd("getCandidates");

    if (!response) {
      response = await getCoordinates(recorderExtId, startTime);
    } else {
      break;
    }
  }

  return response;
};

const closeBrowser = async (recorderExtId: any) => {
  try {
    await chrome.runtime.sendMessage(recorderExtId, { action: "closeBrowser" });
  } catch (err) {
    console.error(err);
  }
};

const fireStepAction = async (options: any, resJSON: any): Promise<any> => {
  const response = await chrome.runtime.sendMessage(options.recorderExtId, {
    action: "stepAction",
    parameters: {
      ...resJSON,
    },
  });

  console.log(response);
  return response;
};

const scrollToAction = async (stepAction: ITestStep, options: any) => {
  const resJSON = { stepAction };
  const res = { error: false };
  const response = await chrome.runtime.sendMessage(options.recorderExtId, {
    action: "scrollTo",
    parameters: {
      ...resJSON,
    },
  });
  if (response.error) res.error = true;
  return res;
};

const customScroll = async (
  options: any,
  i: number,
  scrollCount: number,
  scrollHeight: number,
) => {
  await chrome.runtime.sendMessage(options.recorderExtId, {
    action: "scroll",
    parameters: {
      scroll: ((i - 1) % (scrollCount + 1)) * scrollHeight,
    },
  });
};

const setUrl = async (options: any, url: string) => {
  const res = { error: false };
  const response = await chrome.runtime.sendMessage(options.recorderExtId, {
    action: "visit",
    parameters: { url },
  });
  if (response.error) res.error = true;
  return res;
};

const setWindowSize = async (options: any, stepAction: ITestStep) => {
  const res = { error: false };
  const response = await chrome.runtime.sendMessage(options.recorderExtId, {
    action: "setWindowSize",
    parameters: {
      size: stepAction.jsonbValueSize,
    },
  });
  if (response.error) res.error = true;
  return res;
};

const manageCookies = async (options: any, stepAction: ITestStep) => {
  const res = { error: false, assert: true };
  const response = await chrome.runtime.sendMessage(options.recorderExtId, {
    action: stepAction.type,
    parameters: { cookies: stepAction.jsonbValue },
  });

  if (response.error) res.error = true;
  if (typeof response.assert === "boolean") res.assert = response.assert;
  return res;
};

export {
  closeBrowser,
  customScroll,
  fireStepAction,
  getCoordinates,
  manageCookies,
  scrollToAction,
  setUrl,
  setWindowSize,
};
