import {
  types,
  Instance,
  getParentOfType,
  flow,
  SnapshotOut,
  applySnapshot,
  getSnapshot,
  getRoot,
} from "mobx-state-tree";
import _ from "lodash";
import i18n from "i18next";
import moment from "moment";
import {
  ActionType,
  handleFetch,
  IActionType,
  validateNameByLength,
  validateNameForbiddenChars,
} from "./utils";
import WithSelected from "./WithSelected";
import TestSteps from "./TestSteps";
import TestStepOptions, {
  ErrorStrategies,
  ITestStepOptions,
} from "./TestStepOptions";
import ActionCookies from "./ActionCookies";
import ActionCookie from "./ActionCookie";
import ActionJSONWindowSize from "./ActionJSONWindowSize";
import Element, { IElement } from "./Element";
import Variable from "./Variable";
import { IRoot } from ".";

const TestStepModel = types
  .compose(
    types.model("TestStep", {
      code: types.maybe(types.string),
      id: types.maybe(types.number),
      type: ActionType,
      attributeName: types.maybeNull(types.string),
      value: types.optional(types.string, ""),
      valueAttribute: types.optional(types.string, ""),
      elementName: types.maybeNull(types.string),
      Element: types.maybeNull(Element),
      Variables: types.optional(types.array(Variable), []),
      projectId: types.maybeNull(types.number),
      order: types.number,
      options: types.optional(TestStepOptions, {}),
      StepId: types.maybeNull(types.string),
      jsonbValue: types.optional(types.array(ActionCookie), []),
      createdAt: types.maybe(types.string),
      updatedAt: types.maybe(types.string),
      changerEmail: types.maybe(types.string),
      creatorEmail: types.maybe(types.string),
      status: types.maybeNull(types.string),
      suiteId: types.maybeNull(types.string),
      testId: types.maybeNull(types.string),
      SharedStepId: types.maybeNull(types.string),
      executionStatus: types.maybeNull(types.string),
      scroll: types.maybeNull(types.number),
      showDetails: false,
      saveToValue: false,
      hasErrorMessage: false,
      deleteAllCookies: false,
      shared: false,
      usageCount: types.maybeNull(types.string),
      error: "",
      reportErrorMessage: types.maybeNull(types.string),
      testStepState: types.optional(
        types.enumeration("TestStepState", ["ready", "loading"]),
        "ready",
      ),
    }),
    WithSelected,
    ActionCookies,
    ActionJSONWindowSize,
  )
  .views((self) => ({
    sharedActions(items: ITestStep[]): ITestStep[] {
      return items
        .filter((item) => item.id === 0)
        .sort((s1, s2) => s1.order - s2.order) as ITestStep[];
    },
    sharedActionSteps(items: ITestStep[]): ITestStep[] {
      return items
        .filter(
          (item) =>
            (item.testId === self.SharedStepId && item.id !== 0) ||
            (!self.id && item.shared),
        )
        .sort((s1, s2) => s1.order - s2.order) as ITestStep[];
    },
  }))
  .views((self) => ({
    get title() {
      if (self.type === "sharedAction") return self.value;
      return i18n.t(`test_steps:actions_names.${self.type}`);
    },
    get description() {
      //ToDo: it is temporary description
      const jsonKeys = self.jsonbValue.map((obj) => obj.name).filter((k) => k);
      const jsonKeyValues = self.jsonbValue.filter((obj) => obj.name);
      const jsonKeysList = jsonKeys.map((k) => `'${k}'`).join(", ");

      const cookiesKeysList = !self.deleteAllCookies
        ? i18n.t("test_steps:description_partials.cookiesPartList", {
            cookiesKeys: jsonKeysList,
          })
        : i18n.t("test_steps:description_partials.cookiesAllList");
      const cookiesKeyValues = jsonKeyValues.map((obj) =>
        i18n.t("test_steps:description_partials.keyValue", {
          key: obj.name,
          value: obj.value,
        }),
      );

      const elementLabel = self.Element ? self.Element.elementLabel : null;
      const elementType = self.Element ? self.Element.elementType : null;

      return i18n.t(`test_steps:actions_types.${self.type}`, {
        value: self.value,
        elementLabel,
        attributeName: self.attributeName,
        elementQuotedLabel: `'${elementLabel}' `,
        elementLabelWithDefault: elementLabel || elementType,
        elementType,
        cookiesKeysList: cookiesKeysList,
        cookiesKeyValues: cookiesKeyValues.join(", "),
        interpolation: {
          escapeValue: false,
        },
        windowSizeValue: JSON.stringify(self.jsonbValueSize),
      });
    },
    get updatedWithEmail() {
      return moment(self.updatedAt).format(
        i18n.t("tests:updated_with_email", { email: self.changerEmail }),
      );
    },
    get createdWithEmail() {
      return moment(self.createdAt).format(
        i18n.t("tests:created_with_email", { email: self.creatorEmail }),
      );
    },
    get errorStrategy() {
      return self.options && self.options.errorStrategy;
    },
    get clearField() {
      return (self.options && self.options.clearField) || false;
    },
    get isValid() {
      return (
        !!_.trim(self.value, ". \n") &&
        validateNameByLength(self.value, 255) &&
        validateNameForbiddenChars(self.value) &&
        !self.error
      );
    },
    get validationErrorText() {
      if (!_.trim(self.value, ". \n"))
        return i18n.t("test_steps:errors:required");
      if (!validateNameByLength(self.value, 255))
        return i18n.t("test_steps:errors:length");
      if (!validateNameForbiddenChars(self.value))
        return i18n.t("test_steps:errors:forbidden_chars");
      return self.error;
    },
    get testSharedStepActions(): ITestStep[] {
      const sharedSteps = getParentOfType(self, TestSteps)
        .sharedSteps as ITestStep[];
      const sharedItems = getParentOfType(self, TestSteps).items.filter(
        (item) => item.shared,
      ) as ITestStep[];
      const items = sharedSteps.concat(sharedItems);
      return self.sharedActionSteps(items);
    },
    get locator() {
      return self.Element ? self.Element.name : "";
    },
    get imgSrc() {
      return self.Element
        ? `/apiv2/projects/${self.projectId}/elements/${self.Element.id}/screenshot`
        : "";
    },
    parsedValue(varValues: { [key: string]: string }) {
      const transformUrl = (pathValue: string) => {
        if (/^(http:\/|https:\/|data:text)/.test(pathValue)) return pathValue;
        return `https://${pathValue}`;
      };

      let newValue = self.value;
      if (Object.keys(varValues).length > 0) {
        Object.entries(varValues).forEach(([key, value]) => {
          newValue = newValue.replace(`<${key}>`, value);
          if (self.type === "visit") newValue = transformUrl(newValue);
          return newValue;
        });
      }
      if (self.type === "visit") newValue = transformUrl(newValue);
      return newValue;
    },
  }))
  .actions((self) => ({
    remove: flow(function* () {
      self.testStepState = "loading";
      const url =
        self.id !== 0
          ? `${process.env.REACT_APP_API_URL}/projects/${self.projectId}/suites/${self.suiteId}/tests/${self.testId}/steps/${self.id}`
          : `${process.env.REACT_APP_API_URL}/projects/${self.projectId}/sharedSteps/${self.SharedStepId}`;
      try {
        const response = yield handleFetch(self, url, {
          method: "DELETE",
        });
        if (!response.ok) throw new Error("Could not do remove request");
      } catch (error) {
        throw error;
      } finally {
        self.testStepState = "ready";
      }
    }),
    duplicate: flow(function* () {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.projectId}/suites/${self.suiteId}/tests/${self.StepId}/steps/${self.id}/duplicate`,
          {
            method: "POST",
          },
        );

        if (!response.ok) throw new Error("Could not duplicate the test step");

        return yield response.json();
      } catch (error) {
        throw error;
      }
    }),
    save: flow(function* () {
      try {
        self.value = _.trim(self.value, ". \n");
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.projectId}/suites/${
            self.suiteId
          }/tests/${self.StepId}/steps/${self.id || ""}`,
          {
            method: self.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(getSnapshot(self)),
          },
        );

        if (!response.ok) throw new Error("Could not save the test step");

        if (self.id)
          getParentOfType(self, TestSteps).touchParentTest(self.StepId);
      } catch (error) {
        throw error;
      }
    }),
    saveEmptySharedStep: flow(function* () {
      try {
        self.testStepState = "loading";

        self.value = _.trim(self.value, ". \n");
        const body = {
          step: _.pickBy(getSnapshot(self), (val) => !_.isNil(val)),
        };

        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.projectId}/sharedSteps/empty`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          },
        );
        if (!response.ok) throw new Error("Could not create shared step");

        const sharedStep = yield response.json();
        applySnapshot(self, { ...sharedStep, Variables: [] });
      } finally {
        self.testStepState = "ready";
      }
    }),
  }))
  .actions((self) => ({
    toggleDetails() {
      getParentOfType(self, TestSteps).toggleItemDetails(
        self.id!,
        !self.showDetails,
      );
    },
    toggleSharedStepActions() {
      getParentOfType(self, TestSteps).toggleSharedStepDetails(
        self.SharedStepId!,
        !self.showDetails,
      );
    },
    setType(type: IActionType) {
      const actions = getRoot<IRoot>(self).actions;

      applySnapshot(self, {
        type,
        ..._.pick(
          self,
          "id",
          "order",
          "StepId",
          "showDetails",
          "selected",
          "suiteId",
          "projectId",
          "elementName",
        ),
        Element: actions.isElementType(type) ? self.Element : undefined,
      });
    },
    setValue(value: string) {
      self.value = value;
      self.error = "";
    },
    setScroll(value: number) {
      self.scroll = value;
    },
    setReverse(value: boolean) {
      self.options.setReverse(value);
    },
    setExecutionStatus(status: string) {
      self.executionStatus = status;
    },
    toggleClearField() {
      self.options.toggleClearField();
    },
    toggleSaveToValue() {
      self.saveToValue = !self.saveToValue;
    },
    toggleDeleteAllCookies() {
      self.deleteAllCookies = !self.deleteAllCookies;
    },
    toggleCustomErrorMessage() {
      self.hasErrorMessage = !self.hasErrorMessage;
      self.options.setErrorMessage("");
    },
    setReportErrorMessage(value: string | null) {
      self.reportErrorMessage = value;
    },
    setErrorMessage(value: ITestStepOptions["errorMessage"]) {
      self.options.setErrorMessage(value);
    },
    setErrorStrategy(value: ErrorStrategies) {
      self.options.setErrorStrategy(value);
    },
    setAttributeName(value: string) {
      self.attributeName = value;
    },
    dropElement(item: IElement) {
      self.elementName = item.name;
      self.Element = item;
      self.save();
    },
    onSaveSharedStepClick() {
      getParentOfType(self, TestSteps).saveSharedSteps({
        step: self as ITestStep,
      });
    },
    onCancelSharedStepClick() {
      getParentOfType(self, TestSteps).onCancelAddNewSharedStep(
        self as ITestStep,
      );
    },
    onSaveEmptySharedStepClick() {
      getParentOfType(self, TestSteps).addNewEmptySharedStep({
        step: self as ITestStep,
      });
    },
    editSharedStepName(value: string) {
      const editSharedStep = getParentOfType(self, TestSteps).editSharedStep;
      editSharedStep && editSharedStep.setValue(value);
    },
    onEditSharedStep() {
      getParentOfType(self, TestSteps).onEditSharedStep(getSnapshot(self));
    },
    onSaveSharedStep() {
      getParentOfType(self, TestSteps).onSaveEditSharedStep();
    },
    saveSharedStep: flow(function* () {
      self.testStepState = "loading";
      self.value = _.trim(self.value, ". \n");
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.projectId}/suites/${self.suiteId}/tests/${self.SharedStepId}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...getSnapshot(self), name: self.value }),
          },
        );
        const item = yield response.json();

        if (item.status === "ERROR") throw new Error(item.message);

        applySnapshot(self, {
          ...self,
          changerEmail: item.changerUserEmail,
          updatedAt: item.updatedAt,
        });
        const parent = getParentOfType(self, TestSteps);
        parent.touchParentTest(parent.editSharedStep?.testId);
      } catch (error) {
        throw error;
      } finally {
        self.testStepState = "ready";
      }
    }),
  }))

  .views((self) => ({
    stepDescription(items: ITestStep[]) {
      const mapDescriptions: string = _.map(
        items,
        (testStep, index) => `${index + 1}. ${testStep.description}`,
      ).join(" ");
      return i18n.t("test_steps:shared_step_description", {
        count: items.length,
        descriptions: mapDescriptions,
        interpolation: {
          escapeValue: false,
        },
      });
    },
  }))
  .views((self) => ({
    get testSharedStepDescription() {
      const testSteps = self.testSharedStepActions;
      return self.stepDescription(testSteps);
    },
  }));

const TestStep = types.snapshotProcessor(TestStepModel, {
  preProcessor(sn: ITestStepModel) {
    return Object.assign(
      {
        options: sn.options || TestStepOptions.create({}),
        jsonbValue: sn.jsonbValue || [],
        value: sn.value || "",
        hasErrorMessage: !!(sn.options && sn.options.errorMessage),
      },
      _.pickBy<ITestStepModelSnapshotOut>(sn, (val) => !_.isNil(val)),
    ) as ITestStepModelSnapshotOut;
  },
});

export interface ITestStepModel extends Instance<typeof TestStepModel> {}
export interface ITestStepModelSnapshotOut
  extends SnapshotOut<typeof TestStepModel> {}

export interface ITestStep extends Instance<typeof TestStep> {}

export default TestStep;
